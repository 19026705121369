.CampaignBlock {
    padding: 5px;
    height: 160px;
    width: 120px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    margin: 10px;
}
.campaignImgFrame {
    flex-basis: 75px;
    flex-shrink: 0;
    width: 100px;
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}
.CB-name {
    font-size: 12px;
}
.campaignImg {
    max-height: 75px;
    width: auto;
    
}
.frame {
    height: 75px;
    width: 100px;
    border: 1px solid var(--color5)
}
#CBbutton {
    display: none;
    height: 12px;
    flex-basis: 12px;
    flex-shrink: 0;
    flex-grow: 0;
    font-size: 10px;
}
@media (max-width: 415px) {
    #CBbutton {
        display: block;
        align-self: center;
    }
}