.Landing {
    background: var(--color0);
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    position: fixed;
}
.Boxes {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    height: 100%
}
#create-join {
    position: absolute;
    right: 80px;
    top: 25px;
    height: 20px;
    width: 100px;
}
#invite {
    height: 20px;
    width: 100px;
    position: absolute;
    top: 25px;
    right: auto;
    left: 80px;
}
#logout {
    margin: 8px;
    height: 20px;
    width: 60px;
}
#viewButton {
    position: absolute;
    height: 15px;
    width: 50px;
    top: 55px;
    left: 20px;
}
#viewButton2 {
    position: absolute;
    height: 15px;
    width: 50px;
    top: 55px;
    right: 20px;
}
.Drag-Box {
    background: var(--color-1);
    margin: 25px;
    height: 250px;
    width: 95%;
    display: flex;
    overflow: auto;
    align-items: center;
    justify-content: center;
}
.Staging-Box {
    background: var(--color-1);
    height: 275px;
    width: 180px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.stageingBox {
    height: 200px;
    width: 160px;
    background-color: var(--color0);
    border: 10px var(--color3) inset;
    margin-top: 15px;
}
.stageButton {
    background-color: var(--color1);
    margin-top: 20px;
}
.Campaign-List {
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: auto;
}
@media (min-width: 415px) {
    #viewButton {
        left: 60px;
    }
    #viewButton2 {
        left: 120px;
    }
    #invite {
        top: 33px;
        right: 50px;
        left: auto;
    }
    #create-join {
        top: 6px;
        right: 50px;
    }
}
@media (max-width: 415px) {
    .Staging-Box {
        display: none;
    }
    .Drag-Box {
        flex-direction: column;
        height: 80%;
    }
    .Campaign-List {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: repeat(1fr);
        overflow: auto;
        align-items: center;
        justify-items: center;
        padding: 15px;
    }
}