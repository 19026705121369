.InputBox {
    height: 250px;
    width: 375px;
    position: absolute;
    right: -375px;
    transition: .65s;
    background-color: var(--color0);
    z-index: 1;
}
.RevealInputBox {
    right: 0px;
}
.newCampaign {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 100px 10px 30px 10px 100px 1fr;
    grid-template-areas: 
    ". . . . . . ."
    ". campaign . . . desc ."
    ". image . . . desc ."
    ". limit . . . desc ."
    ". . . . . . ."
    ". send . . . cancel ."
    ". . . . . . ."
}
.campaignNew {
    height: 20px;
    grid-area: campaign;
}
.imgNew {
    height: 20px;
    grid-area: image;
}
.levelNew {
    display: flex;
    align-items: center;
    grid-area: limit;
}
.levelBox {
    font-size: 12px;
    display: inline-block;
}
.textNew {
    grid-area: desc;
}
.sendNew {
    position: relative;
    left: 15px;
    grid-area: send;
}
.cancelNew {
    position: relative;
    right: 15px;
    grid-area: cancel;
}
#newCampaignButton {
    height: 20px;
}
#newCampaignButton2 {
    height: 20px;    
}
.inviteSend {
    display: grid;
    grid-template-rows: 15px 1fr 1fr 1fr 15px 1fr 1fr;
    grid-template-columns: 50px 1fr 1fr 50px;
    grid-template-areas: 
    ". . . ."
    ". . . ."
    ". campaign campaign ."
    ". text text ."
    ". . . ."
    ". send cancel ."
    ". . . .";
}
.campaignJoin {
    grid-area: campaign;
}
.textJoin {
    grid-area: text;
}
.sendJoin {
    grid-area: send;
}
.cancelJoin {
    grid-area: cancel;
    justify-self: end;
}

@media (min-width: 415px) {

}