.Character-Box {
    position: absolute;
    margin: auto;
    left: auto;
    right: auto;
    top: 35%;
    bottom: 35%;
    background-color: var(--color0);
    height: 200px;
    width: 200px;
    padding: 10px;
}
.Background {
    opacity: .5;
}
.Foreground {
    opacity: 1;
}
.CharacterLanding {
    display: flex;
    align-items: center;
    flex-direction: column;
}
.CharacterDisplayBox {
    grid-area: realstuff;
    height: calc(85vh - 30px);
    width: calc(80vw - 30px);
    background: var(--color-1);
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    overflow: auto;
}
.CharacterButtonsContainer {
    position: absolute;
    top: 40vh;
    height: 10vh;
    width: 20px;
    left: 0;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    border: 5px outset var(--color3);
    border-left: none;
    background-color: var(--color-1);
}
.CharacterButtonsContainer:hover {
    background-color: var(--color0);
}
.CharacterButtonsContainer:focus {
    outline: none;
}
.homeButton {
    width: 10px;
    background: none;
    border: none;
}
.homeButton:focus {
    outline: none;
}
#ChCreationButton {
    background-color: var(--color8);
    flex-basis: 30px;
    flex-shrink: 0;
    height: 30px;
    width: 30px;
    border-radius: 30px;
    color: var(--color10);
    font-size: 20px;
    font-weight: bold;
    padding: 2px 0 0 0;
}
#ChCreationButton:hover {
    background-color: var(--color9);
}
.NewButtonBox {
    flex-basis: 200px;
    flex-shrink: 0;
    height: 200px;
    width: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: dotted 1px black;
    margin-top: 20px;
}
#ChImportButton {
    position: absolute;
    top: 15px;
    right: 15px;
    background-color: var(--color8);
    height: 25px;
    width: 80px;
    color: var(--color10);
    font-size: 20px;
    opacity: .5;
}
#ChImportButton:hover {
    background-color: var(--color9);
}
#IncrementButton {
    height: 10px;
    width: 10px;
    border-radius: 10px;
    font-size: 10px;
    padding: 0 0 1px 1px;
    display: inline;
}
#IncrementButtonPlus {
    height: 10px;
    width: 10px;
    border-radius: 10px;
    font-size: 8px;
    padding: 1px 0 0px 1px;
    display: inline;
}
#IncrementButtonMinus {
    height: 10px;
    width: 10px;
    border-radius: 10px;
    font-size: 10px;
    padding: 0 0 1px 1px;
    display: inline;
}
#IncrementButtonX {
    height: 10px;
    width: 10px;
    border-radius: 10px;
    font-size: 8px;
    padding: 0 0 1px 1px;
    display: inline;
}
.GridForCorners {
    margin-top: 30px;
    background: var(--color-1);
    display: grid;
    grid-template-rows: 30px 1fr 30px;
    grid-template-columns: 30px 1fr 30px;
    grid-template-areas: 
    "TL . TR"
    ". realstuff ."
    "BL . BR";
    justify-content: center;
}
.corner {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 
    "TL TR"
    "BL BR"
}
.c1 {
    grid-area: TL;
}
.c2 {
    grid-area: TR;
}
.c3 {
    grid-area: BL;
}
.c4 {
    grid-area: BR;
}
.TL {
    grid-area: TL;
}
.TR {
    grid-area: TR;
}
.BL {
    grid-area: BL;
}
.BR {
    grid-area: BR;
}
#ImportInput {

}
.ImportBox {
    display: flex;
    flex-direction: column;
}
@media (min-width: 415px) {
    .CharacterDisplayBox {
        display: grid;
        grid-template-rows: repeat(autofill 1fr);
        grid-template-columns: 1fr 1fr;
        align-items: center;
        justify-items: center;
    }
}