@import url(https://fonts.googleapis.com/css?family=Forum);
.Login {
    background-color: var(--color0);
    height: 100vh;
    width: 100%;
    display: grid;
    grid-template-rows: 50px 1fr 200px 1fr 50px;
    grid-template-columns: 50px 1fr 200px 1fr 50px;
    grid-column-gap: 5px;
    grid-row-gap: 5px;
    grid-template-areas: 
        "TL . . . TR"
        ". . . . ."
        ". . paper . ."
        ". . . . ."
        "BL . . . BR";
}
.username-input {
    grid-area: username;
    justify-self: center;
}
.password-input {
    grid-area: password;
    justify-self: center;
}
.login-button {
    grid-area: login;
    justify-self: center;
}
.register-button {
    grid-area: register;
    justify-self: center;
}
.brown {
    background-color: var(--color5);
}
.c1 {
    grid-area: TL;
}
.c2 {
    grid-area: TR;
}
.c3 {
    grid-area: BL;
}
.c4 {
    grid-area: BR;
}
.TL {
    grid-area: TL;
}
.TR {
    grid-area: TR;
}
.BL {
    grid-area: BL;
}
.BR {
    grid-area: BR;
}
.LoginPaper {
    height: 200px;
    width: 200px;
    grid-area: paper;
    justify-self: center;
    display: grid;
    grid-template-rows: 1fr 30px 30px 30px 30px 1fr;
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 15px;
    grid-template-areas: 
    ". . ."
    ". username ."
    ". password ."
    ". login ."
    ". register ."
    ". . ."
}
.corner {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 
    "TL TR"
    "BL BR"
}
@media (min-width: 415px) {
    .Login {
        grid-template-rows: 50px 1fr 400px 1fr 50px;
    }
    .LoginPaper {
        grid-row-gap: 30px;
        grid-template-rows: 1fr 30px 30px 30px 30px 2fr;
        height: 400px;
        width: 400px;
    }
}
.CBplayerName {
    font-size: 12px;
}
.CBplayerRace {
    font-size: 11px;
    position: relative;
    left: 1px;
}
.CBPD-class_name {
    position: relative;
    left: 3px;
    font-size: 10px;
}
.CampaignBlock {
    padding: 5px;
    height: 160px;
    width: 120px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    margin: 10px;
}
.campaignImgFrame {
    flex-basis: 75px;
    flex-shrink: 0;
    width: 100px;
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
}
.CB-name {
    font-size: 12px;
}
.campaignImg {
    max-height: 75px;
    width: auto;
    
}
.frame {
    height: 75px;
    width: 100px;
    border: 1px solid var(--color5)
}
#CBbutton {
    display: none;
    height: 12px;
    flex-basis: 12px;
    flex-shrink: 0;
    flex-grow: 0;
    font-size: 10px;
}
@media (max-width: 415px) {
    #CBbutton {
        display: block;
        align-self: center;
    }
}
.InputBox {
    height: 250px;
    width: 375px;
    position: absolute;
    right: -375px;
    -webkit-transition: .65s;
    transition: .65s;
    background-color: var(--color0);
    z-index: 1;
}
.RevealInputBox {
    right: 0px;
}
.newCampaign {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 100px 10px 30px 10px 100px 1fr;
    grid-template-areas: 
    ". . . . . . ."
    ". campaign . . . desc ."
    ". image . . . desc ."
    ". limit . . . desc ."
    ". . . . . . ."
    ". send . . . cancel ."
    ". . . . . . ."
}
.campaignNew {
    height: 20px;
    grid-area: campaign;
}
.imgNew {
    height: 20px;
    grid-area: image;
}
.levelNew {
    display: flex;
    align-items: center;
    grid-area: limit;
}
.levelBox {
    font-size: 12px;
    display: inline-block;
}
.textNew {
    grid-area: desc;
}
.sendNew {
    position: relative;
    left: 15px;
    grid-area: send;
}
.cancelNew {
    position: relative;
    right: 15px;
    grid-area: cancel;
}
#newCampaignButton {
    height: 20px;
}
#newCampaignButton2 {
    height: 20px;    
}
.inviteSend {
    display: grid;
    grid-template-rows: 15px 1fr 1fr 1fr 15px 1fr 1fr;
    grid-template-columns: 50px 1fr 1fr 50px;
    grid-template-areas: 
    ". . . ."
    ". . . ."
    ". campaign campaign ."
    ". text text ."
    ". . . ."
    ". send cancel ."
    ". . . .";
}
.campaignJoin {
    grid-area: campaign;
}
.textJoin {
    grid-area: text;
}
.sendJoin {
    grid-area: send;
}
.cancelJoin {
    grid-area: cancel;
    justify-self: end;
}

@media (min-width: 415px) {

}
.InviteBox {
    height: auto;
    width: 150px;
    position: absolute;
    right: -375px;
    top: 250px;
    -webkit-transition: .65s;
    transition: .65s;
    border: 5px var(--color3) outset;
    border-right: none;
}
.InviteBoxBox {
    height: 200px;
    display: flex;
    flex-direction: column;
    overflow: auto;
}
.inviteBoxInvite {
    background: var(--color0);
    height: 100px;
    width: 150px;
    flex-shrink: 0;
    border-bottom: 1px black solid;
    display: grid;
    grid-template-rows: 25px 1fr 1fr 20px;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 
    "name ."
    "message ."
    ". ."
    "accept decline"
}
.IBname {
    grid-area: name;
    font-size: 12px;
}
.IBmessage {
    grid-area: message;
    font-size: 10px;
}
#inviteBoxButton1 {
    grid-area: accept;
    height: 15px;
    width: 50px;
    font-size: 12px;
}
#inviteBoxButton2 {
    grid-area: decline;
    height: 15px;
    width: 50px;
    font-size: 12px;
}
.IBbuttonBox {
    height: 20px;
    border-top: 5px outset var(--color3);
    display: flex;
    justify-content: center;
    align-items: center;
}
#IBcancel {
    height: 13px;
    width: 50px;
}
.RevealInviteBox {
    right: 0px;
}

.Landing {
    background: var(--color0);
    height: 100vh;
    width: 100vw;
    overflow: hidden;
    position: fixed;
}
.Boxes {
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    height: 100%
}
#create-join {
    position: absolute;
    right: 80px;
    top: 25px;
    height: 20px;
    width: 100px;
}
#invite {
    height: 20px;
    width: 100px;
    position: absolute;
    top: 25px;
    right: auto;
    left: 80px;
}
#logout {
    margin: 8px;
    height: 20px;
    width: 60px;
}
#viewButton {
    position: absolute;
    height: 15px;
    width: 50px;
    top: 55px;
    left: 20px;
}
#viewButton2 {
    position: absolute;
    height: 15px;
    width: 50px;
    top: 55px;
    right: 20px;
}
.Drag-Box {
    background: var(--color-1);
    margin: 25px;
    height: 250px;
    width: 95%;
    display: flex;
    overflow: auto;
    align-items: center;
    justify-content: center;
}
.Staging-Box {
    background: var(--color-1);
    height: 275px;
    width: 180px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.stageingBox {
    height: 200px;
    width: 160px;
    background-color: var(--color0);
    border: 10px var(--color3) inset;
    margin-top: 15px;
}
.stageButton {
    background-color: var(--color1);
    margin-top: 20px;
}
.Campaign-List {
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: auto;
}
@media (min-width: 415px) {
    #viewButton {
        left: 60px;
    }
    #viewButton2 {
        left: 120px;
    }
    #invite {
        top: 33px;
        right: 50px;
        left: auto;
    }
    #create-join {
        top: 6px;
        right: 50px;
    }
}
@media (max-width: 415px) {
    .Staging-Box {
        display: none;
    }
    .Drag-Box {
        flex-direction: column;
        height: 80%;
    }
    .Campaign-List {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: repeat(1fr);
        overflow: auto;
        align-items: center;
        justify-items: center;
        padding: 15px;
    }
}
.eyeImg {
    height: 10px;
    width: 10px;
}
.eyeButton {
    background-color: var(--color0);
    border: none;
    border-radius: 10px;
    position: absolute;
    right: 3px;
    top: 3px;
}
.eyeButton:hover {
    background-color: var(--color8)
}
.eyeButton:focus {
    outline: none;
}
.ChBframe {
    flex-basis: 100px;
    flex-shrink: 0;
    width: 125px;
    align-self: center;
    display: flex;
    justify-content: center;
    overflow: hidden;
}
.CharacterCard {
    position: relative;
    height: 200px;
    width: 160px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    padding: 5px;
    margin-top: 20px;
}
.ChBimage {
    max-height: 98px;
    align-self: center;
    width: 123px;
}
.nameTextChB {
    font-size: 14px;
    font-weight: bold;
}
.nameTextChBBox {
    flex-basis: 15px;
    flex-shrink: 0;
    width: 100px;
    height: 14px;
    overflow: hidden;
}
.ChBrace {
    font-size: 12px;
    font-weight: bold
}
.ChBclass {
    font-size: 12px;
    margin-left: 2px;
}
.BreakChB {
    border: .5px solid black;
}
.ChBstats {
    display: flex;
    flex-basis: 20px;
    flex-shrink: 0;
}
.statsChB {
    font-size: 10px;
    margin-right: 3px;
    font-weight: bold
}
.statsChBnums {
    font-size: 10px;
    font-weight: normal;
}
.ChBsavesBox {
    margin-top: 3px;
}
.ChBsaves {
    margin-bottom: 4px;
    font-size: 12px;
}
.ChBsavesNums {
    font-size: 12px;
    font-weight: 700;
    display: inline;
    margin-right: 5px;
    border: .5px black solid;
    border-radius: 150px;
    padding: 1px;
    float: right;
}
.alignmentSelect {
    position: relative;
    background-color: var(--color0);
    border: 1px var(--color9) solid;
    border-radius: 10px;
    margin-top: 20px;
}
.alignmentSelect:after {
    position: absolute;
  content: "";
  top: 7px;
  right: 7px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: var(--color10) transparent transparent transparent;
}
select:focus {
    outline: none;
}
.CharacterCreation1 {
    position: relative;
    height: 200px;
    width: 200px;
    overflow: auto;
}
.CreationStatsNums {
    display: inline;
}
.CH1ButtonBox {
    width: 180px;
    display: flex;
    justify-content: space-around;
}
#ButtonCh1 {
    height: 20px;
    width: 75px;
}
.StatNameCH1 {
    display: inline;
}
.statContainerCh1 {
    margin-bottom: 10px;
}
.InlineText {
    display: inline;
    margin: 0 5px;
}
.Ch4 {
    overflow: auto;
    height: 180px;
    width: 180px;
}
#prevStepCh4 {
    position: relative;
    height: 20px;
    width: 75px;
    margin-right: 28px
}
#nextStepCh4 {
    position: relative;
    height: 20px;
    width: 75px;
}
#ChCreationCancel {
    background: var(--color9);
    border: none;
    position: absolute;
    top: 5px;
    right: 5px;
    font-size: 10px;
    height: 15px;
    width: 15px;
    border-radius: 15px;
    color: var(--color10);
    padding: 0 0 0 .5px;
}
#ChCreationCancel:hover {
    background-color: var(--color8);
}
#nextStep {
    position: absolute;
    bottom: 8px;
    right: 8px;
    height: 20px;
    width: 75px;
}
#prevStep {
    position: absolute;
    bottom: 8px;
    left: 8px;
    height: 20px;
    width: 75px;
}


.quantityMarker {
  height: 5px;
  width: 5px;
  flex-shrink: 0;
  background: #444;
  border: #222 solid 1px;
  border-radius: 50%;
  margin: 1px 1px 0px 0px;
}
.qm100 {
  background: gold;
}
.qm20 {
  background: silver;
}
.qm5 {
  background: #d1a684;
}
.quantityBox {
  height: 12px;
  width: 60px;
  display: flex;
  flex-wrap: wrap-reverse;
  align-content: flex-start;
}
.Saves {
  border: 1px black solid;
  border-radius: 20px;
  padding: 1px;
}
.Justify-End {
  margin-left: 5px;
  justify-content: space-between;
}
.Thac {
  border: 1px black solid;
  border-left: 0.5px;
  border-right: 0.5px;
  height: 15px;
  width: 20px;
}
.WeaponBox {
  border: 1px dotted var(--color10);
  padding: 2px;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.WeaponsContainer {
  display: flex;
}
@media (max-width: 415px) {
  .THAC2 {
    display: none;
  }
}

.Spell {
    border: 1px black solid;
    border-left: none;
    border-right: none;
    font-size: 10px;
    padding: 0 3px 0 3px;
}
.Magictitle {
    margin-top: 5px;
}

.CharacterSheet {
    overflow: auto;
}
#CharacterSheetEdit {
    height: 20px;
    width: 75px;
    margin-right: 25px;
}
#CharacterSheetAdvanced {
    height: 20px;
    width: 100px;
}
.SectionTitle {
    font-size: 20px;
    display: inline;
    margin-right: 5px;
    margin-top: 5px;
}
.Loading {
    font-size: 40px;
}
#SectionButton {
    margin-top: 5px;
    height: 20px;
    width: 20px;
    border-radius: 20px;
    display: inline;
}
.SectionTitleBox {
    display: flex;
    align-items: center;
}
#RemoveButtonCH {
    height: 20px;
    width: 75px;
    order: 1
}
#SubmitButtonCH {
    order: 2;
    height: 20px;
    width: 75px;
}
.RemoveSubmitButtons {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
}
.Container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}
.ContainerColumn {
    display: flex;
    align-items: center;
    flex-direction: column;
}
.Header {
    font-size: 14px;
    line-height: 14px;
    margin-right: 3px;
}
.Smalltext {
    font-size: 10px;
    line-height: 10px;
}
.MinuteText {
    font-size: 8px;
}
.Text {
    font-size: 12px;
    line-height: 14px;
}
.TextBackground {
    background-color: var(--color-1);
}
#bigbox {
    position: absolute;
    top: 25%;
    left: 25%;
    flex-basis: 350px;
    flex-shrink: 0;
    height: 50%;
    width: 50%;
    overflow: auto;
    background: var(--color0);
    padding: 10px;
}
@media (max-width: 415px) {
    #bigbox {
        width: 100%;
        left: 0;
    }
}
.Character-Box {
    position: absolute;
    margin: auto;
    left: auto;
    right: auto;
    top: 35%;
    bottom: 35%;
    background-color: var(--color0);
    height: 200px;
    width: 200px;
    padding: 10px;
}
.Background {
    opacity: .5;
}
.Foreground {
    opacity: 1;
}
.CharacterLanding {
    display: flex;
    align-items: center;
    flex-direction: column;
}
.CharacterDisplayBox {
    grid-area: realstuff;
    height: calc(85vh - 30px);
    width: calc(80vw - 30px);
    background: var(--color-1);
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    overflow: auto;
}
.CharacterButtonsContainer {
    position: absolute;
    top: 40vh;
    height: 10vh;
    width: 20px;
    left: 0;
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    border: 5px outset var(--color3);
    border-left: none;
    background-color: var(--color-1);
}
.CharacterButtonsContainer:hover {
    background-color: var(--color0);
}
.CharacterButtonsContainer:focus {
    outline: none;
}
.homeButton {
    width: 10px;
    background: none;
    border: none;
}
.homeButton:focus {
    outline: none;
}
#ChCreationButton {
    background-color: var(--color8);
    flex-basis: 30px;
    flex-shrink: 0;
    height: 30px;
    width: 30px;
    border-radius: 30px;
    color: var(--color10);
    font-size: 20px;
    font-weight: bold;
    padding: 2px 0 0 0;
}
#ChCreationButton:hover {
    background-color: var(--color9);
}
.NewButtonBox {
    flex-basis: 200px;
    flex-shrink: 0;
    height: 200px;
    width: 160px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: dotted 1px black;
    margin-top: 20px;
}
#ChImportButton {
    position: absolute;
    top: 15px;
    right: 15px;
    background-color: var(--color8);
    height: 25px;
    width: 80px;
    color: var(--color10);
    font-size: 20px;
    opacity: .5;
}
#ChImportButton:hover {
    background-color: var(--color9);
}
#IncrementButton {
    height: 10px;
    width: 10px;
    border-radius: 10px;
    font-size: 10px;
    padding: 0 0 1px 1px;
    display: inline;
}
#IncrementButtonPlus {
    height: 10px;
    width: 10px;
    border-radius: 10px;
    font-size: 8px;
    padding: 1px 0 0px 1px;
    display: inline;
}
#IncrementButtonMinus {
    height: 10px;
    width: 10px;
    border-radius: 10px;
    font-size: 10px;
    padding: 0 0 1px 1px;
    display: inline;
}
#IncrementButtonX {
    height: 10px;
    width: 10px;
    border-radius: 10px;
    font-size: 8px;
    padding: 0 0 1px 1px;
    display: inline;
}
.GridForCorners {
    margin-top: 30px;
    background: var(--color-1);
    display: grid;
    grid-template-rows: 30px 1fr 30px;
    grid-template-columns: 30px 1fr 30px;
    grid-template-areas: 
    "TL . TR"
    ". realstuff ."
    "BL . BR";
    justify-content: center;
}
.corner {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    grid-template-areas: 
    "TL TR"
    "BL BR"
}
.c1 {
    grid-area: TL;
}
.c2 {
    grid-area: TR;
}
.c3 {
    grid-area: BL;
}
.c4 {
    grid-area: BR;
}
.TL {
    grid-area: TL;
}
.TR {
    grid-area: TR;
}
.BL {
    grid-area: BL;
}
.BR {
    grid-area: BR;
}
#ImportInput {

}
.ImportBox {
    display: flex;
    flex-direction: column;
}
@media (min-width: 415px) {
    .CharacterDisplayBox {
        display: grid;
        grid-template-rows: repeat(autofill 1fr);
        grid-template-columns: 1fr 1fr;
        align-items: center;
        justify-items: center;
    }
}
.EditCampaignComponent {
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
}
.nameEdit {
    grid-area: name;
    display: inline;
}
.nameEditInput {
    grid-area: nameInput;
    display: inline;
    width: 80px;
}
.imgEdit {
    grid-area: image;
    display: inline;
}
.imgEditInput  {
    grid-area: imageInput;
    display: inline;
    width: 60px;
}
.levelEdit {
    grid-area: level;
}
.descEdit {
    grid-area: desc;
}
.descEditInput {
    grid-area: descInput;
}
.submitEdit {
    grid-area: submit;
    margin-right: 15px;
}
.resetEdit {
    grid-area: reset;
    display: inline;
}
.EditLevelRestrictions {
    display: flex;
    align-items: center;
}
.editText {
    font-size: 14px;
}
#EditButton {
    height: 15px;
    width: 50px;
}
#textareaEdit {
    background-color: var(--color0)
}
#editInput {
    background-color: var(--color-1);
}

.DeleteCampaign {
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.DeleteButtonBox {
    display: flex;
}
#deleteYes {
    height: 20px;
    width: 50px;
}
#deleteNo {
    height: 20px;
    width: 50px;
}
.InviteButtonBox {
    display: flex;
    align-items: center;
    justify-content: space-around;
    height: 200px;
    flex-direction: column;
}
#InviteInput {
    background-color: var(--color-1)
}
.InviteText {
    font-size: 12px;
}
#InviteButton {
    height: 15px;
    width: 50px;
}
.Requests {
    height: 200px;
    overflow: auto;
    padding: 5px;
}
.requests {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 200px;
}
#RequestsLeave {
    flex-basis: 20px;
    flex-shrink: 0;
    align-self: center;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
}
.ToggleRequests {
    align-self: center;
}
.Request {
    border-bottom: 1px black solid;
}
.RequestUsername {
    font-size: 16px;
    display: flex;
}
.RequestMessage {
    font-size: 12px;
    width: 100px;
    height: 24px;
    overflow-wrap: break-word;
    overflow: scroll;
    margin-top:3px;
}
.RequestButtons {
    display: flex;
    justify-content: space-around;
    height: 15px;
}
#RequestButtons {
    height: 15px;
    width: 50px;
}
.RemovePlayer {
    height: 200px;
}
.RemoveButtonBox {
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.removeBox {
    height: 200px;
    padding: 5px;
    overflow: auto;
}
.RemovePlayerContainer {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    border-bottom: 1px black solid;
}
.removeText {
    font-size: 14px;
}
#RemoveButton {
    height: 12px;
    width: 12px;
    padding: 0px;
    border-radius: 12px;
    margin-left: 5px;
    font-size: 14px;
    font-weight: bold;
    line-height: 0px;
    padding: 0px 0px 2px 1px;
}

.LoadingLanding {
    background-color: var(--color0);
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.LoadingTextCampaign {
    font-size: 40px;
    font-weight: bold;
}
.CampaignLanding {
    width: 100vw;
    height: 100vh;
    background: var(--color0);
    position: fixed;
}
.Box {
    height: 200px;
    width: 20vw;
    overflow: auto;
    background: var(--color-1)
}
.EditCampaignBox {
    position: absolute;
    top: 8px;
    left: 8px;
}
.DeleteCampaignBox {
    position: absolute;
    bottom: 8px;
    left: 8px;
}
.RemovePlayerBox {
    position: absolute;
    bottom: 8px;
    right: 8px;
}   
.InvitePlayerBox {
    position: absolute;
    top: 8px;
    right: 8px;
}
.RequestsBox {
    position: absolute;
    bottom: 8px;
    left: 40vw;
}
.LeaveCampaignBox {
    position: absolute;
    top: 37vh;
    left: 8px;
}
.returnButton {
    position: absolute;
    left: 47.5vw;
    width: 5vw;
    display: flex;
    justify-content: center;
    border: 5px inset var(--color3);
    border-top: none;
    background: var(--color-1);
}
.returnButton:hover {
    background-color: var(--color0);
}
.HomeButton {
    background: none;
    border: none;
}
.HomeButton:focus {
    outline: none;
}
.characterToggle {
    position: absolute;
    right: 0px;
    top: 47.5vh;
    height: 5vh;
    width: 75px;
    display: flex;
    justify-content: flex-start;
    border: var(--color3) 5px inset;
    border-right: none;
    background: var(--color-1);
}
.characterToggle:hover {
    background-color: var(--color0);
}
.characterToggle:focus {
    outline: none;
}
.landing-screen {
    height: 100%;
    width: 100%;
    -webkit-transition: .65s;
    transition: .65s;
}
.hidden-left{
    position: absolute;
    left: -100%
}
.hidden-right{
    position: absolute;
    right: -100%
}
.front-and-center {
    position: absolute;
    right: 0px;
    left: 0px;
}
.right-and-center {
    position: absolute;
    right: 0px;
}
.left-and-center {
    position: absolute;
    left: 0px;
}
@media (max-width: 415px) {
    .Box {
        width: 40vw;
    }
    .RequestsBox {
        top: 37vh;
        left: 8px;
    }
    .returnButton {
        width: 13vw;
        left: 43.5vw;
    }
}
:root {
    --color-1: #fff7e6;
    --color0: #F1ECE1;
    --color1: #FFD2AB;
    --color2: #D79E6D;
    --color3: #AC6F3B;
    --color4: #834917;
    --color5: #582901;
    --color6: #351900;
    --color7: #1A0C00;
    --color8: #ddd;
    --color9: #bbb;
    --color10: #333;
}
html {
    overflow: hidden;
}
body {
    font-family: 'Forum', serif;
    height: 100vh;
    width: 100vw;
}
.sup {
    vertical-align: super;
    font-size: 8px;
    display: inline;
}
.MultilineDisplay {
    white-space: pre-wrap;
}
.button {
    border: none;
    border-radius: 3px;
    background-color: #834917;
    background-color: var(--color4);
    height: 30px;
    width: 100px;
    font-family: 'Forum', serif;
    color: #ddd;
    color: var(--color8);
}
.button:hover {
    background: #AC6F3B;
    background: var(--color3);
}
.button:focus {
    outline: none;
    background: #AC6F3B;
    background: var(--color3);
}
.input {
    background-color: #F1ECE1;
    background-color: var(--color0);
    border: none;
    border-bottom: #bbb 1px solid;
    border-bottom: var(--color9) 1px solid;
    font-family: 'Forum', serif;
    color: #333;
    color: var(--color10);
    font-size: 12px;
}
.input:focus {
    outline: none;
}
.textarea {
    background-color: #fff7e6;
    background-color: var(--color-1);
    border: none;
    font-family: 'Forum', serif;
}
.textarea:focus {
    outline: none;
}
.text {
    font-family: 'Forum', serif;
    color: #333;
    color: var(--color10);
}
.paper {
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}
select {
    padding: 0 0 3px 5px; ;
    background-color: transparent;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
}
@media (min-width: 415px) {
    .button {
        border-radius: 5px;
    }
}
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
	display: block;
}
body {
	line-height: 1;
	overflow: hidden;
}
ol, ul {
	list-style: none;
}
blockquote, q {
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
	content: '';
	content: none;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
* {
  box-sizing: border-box;
}
