.eyeImg {
    height: 10px;
    width: 10px;
}
.eyeButton {
    background-color: var(--color0);
    border: none;
    border-radius: 10px;
    position: absolute;
    right: 3px;
    top: 3px;
}
.eyeButton:hover {
    background-color: var(--color8)
}
.eyeButton:focus {
    outline: none;
}
.ChBframe {
    flex-basis: 100px;
    flex-shrink: 0;
    width: 125px;
    align-self: center;
    display: flex;
    justify-content: center;
    overflow: hidden;
}
.CharacterCard {
    position: relative;
    height: 200px;
    width: 160px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    padding: 5px;
    margin-top: 20px;
}
.ChBimage {
    max-height: 98px;
    align-self: center;
    width: 123px;
}
.nameTextChB {
    font-size: 14px;
    font-weight: bold;
}
.nameTextChBBox {
    flex-basis: 15px;
    flex-shrink: 0;
    width: 100px;
    height: 14px;
    overflow: hidden;
}
.ChBrace {
    font-size: 12px;
    font-weight: bold
}
.ChBclass {
    font-size: 12px;
    margin-left: 2px;
}
.BreakChB {
    border: .5px solid black;
}
.ChBstats {
    display: flex;
    flex-basis: 20px;
    flex-shrink: 0;
}
.statsChB {
    font-size: 10px;
    margin-right: 3px;
    font-weight: bold
}
.statsChBnums {
    font-size: 10px;
    font-weight: normal;
}
.ChBsavesBox {
    margin-top: 3px;
}
.ChBsaves {
    margin-bottom: 4px;
    font-size: 12px;
}
.ChBsavesNums {
    font-size: 12px;
    font-weight: 700;
    display: inline;
    margin-right: 5px;
    border: .5px black solid;
    border-radius: 150px;
    padding: 1px;
    float: right;
}