@import url('https://fonts.googleapis.com/css?family=Forum');

:root {
    --color-1: #fff7e6;
    --color0: #F1ECE1;
    --color1: #FFD2AB;
    --color2: #D79E6D;
    --color3: #AC6F3B;
    --color4: #834917;
    --color5: #582901;
    --color6: #351900;
    --color7: #1A0C00;
    --color8: #ddd;
    --color9: #bbb;
    --color10: #333;
}
html {
    overflow: hidden;
}
body {
    font-family: 'Forum', serif;
    height: 100vh;
    width: 100vw;
}
.sup {
    vertical-align: super;
    font-size: 8px;
    display: inline;
}
.MultilineDisplay {
    white-space: pre-wrap;
}
.button {
    border: none;
    border-radius: 3px;
    background-color: var(--color4);
    height: 30px;
    width: 100px;
    font-family: 'Forum', serif;
    color: var(--color8);
}
.button:hover {
    background: var(--color3);
}
.button:focus {
    outline: none;
    background: var(--color3);
}
.input {
    background-color: var(--color0);
    border: none;
    border-bottom: var(--color9) 1px solid;
    font-family: 'Forum', serif;
    color: var(--color10);
    font-size: 12px;
}
.input:focus {
    outline: none;
}
.textarea {
    background-color: var(--color-1);
    border: none;
    font-family: 'Forum', serif;
}
.textarea:focus {
    outline: none;
}
.text {
    font-family: 'Forum', serif;
    color: var(--color10);
}
.paper {
    box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
}
select {
    padding: 0 0 3px 5px; ;
    background-color: transparent;
    border: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
}
@media (min-width: 415px) {
    .button {
        border-radius: 5px;
    }
}