.DeleteCampaign {
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.DeleteButtonBox {
    display: flex;
}
#deleteYes {
    height: 20px;
    width: 50px;
}
#deleteNo {
    height: 20px;
    width: 50px;
}